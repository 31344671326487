import { TextField } from "@mui/material";
import React from "react";
import BottomSpacer from "./BottomSpacer";

export default function PasswordField({
  error,
  helperText,
  value,
  onChange,
  name,
  label,
  style,
}) {
  return (
    <>
      <TextField
        error={error}
        helperText={error ? helperText : ""}
        fullWidth
        label={label}
        type="password"
        autoComplete="current-password"
        value={value}
        name={name}
        onChange={(e) => onChange(e)}
        sx={style ? style : {}}
        inputProps={{ inputmode: "tel" }}
        required
      />
      <BottomSpacer isVisible={!error} />
    </>
  );
}
