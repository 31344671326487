import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { Button } from "@mui/material";
import PasswordField from "../../components/PasswordField";
import PhoneNumberField from "../../components/PhoneNumberField";
import axios from "../../apis/axios";
import Loader from "../../components/Loader";
import { Auth } from "aws-amplify";
import { useParams, useNavigate } from "react-router-dom";
import { buttonStyle, textFieldStyle, cardStyle, titleStyle } from "./Styles";
import CaptchaModal from "../../components/Modal";
import { validateInputs, phoneValidate } from "../../utils/Validation";

export default function Dashboard() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [apiData, setApiData] = useState(null);
  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const onSubmit = async () => {
    setIsLoading(true);
    if (!validateInputs(state)) {
      alert("Invalid Inputs");
      return;
    }
    if ((new Date() - new Date(apiData.start_time)) / 1000 > 1800) {
      navigate("/failed", {
        state: {
          reason: "Session expired",
        },
      });
      return;
    }
    if (state.phoneNumber !== apiData.phone_number) {
      navigate("/failed", {
        state: {
          reason: "Phone number is not valid",
        },
      });
      return;
    }
    try {
      const res = await axios.post(`/api/v2/auth/forget-password/${id}/`,{
        password: state.password
      });
      if (res.data.data.is_valid === true) {
        Auth.signIn("+88" + apiData.phone_number, apiData.otp)
          .then((user) => {
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
              Auth.completeNewPassword(user, state.password)
                .then((user) => {
                  navigate("/success");
                })
                .catch((e) => {
                  navigate("/failed");
                });
            } else {
              navigate("/failed");
            }
          })
          .catch((e) => {
            navigate("/failed");
          });
      }
    } catch (err) {
      if (err?.response?.status === 404) {
        navigate("/failed", {
          state: {
            reason: "Session expired",
          },
        });
      } else {
        navigate("/failed", {
          state: {
            reason: null,
          },
        });
      }
    }
  };

  const onChangeHandler = (e) => {
    const len = e.target.value.length;

    if (
      e.target.value &&
      (e.target.value.charCodeAt(len - 1) < 48 ||
        e.target.value.charCodeAt(len - 1) > 57)
    ) {
      return;
    }
    let tempState = {
      ...state,
      [e.target.name]: e.target.value,
    };

    if (
      (e.target.name === "phoneNumber" && len <= 11) ||
      (e.target.name !== "phoneNumber" && len <= 6)
    )
      setState(tempState);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/v2/auth/forget-password/${id}/`);
        setApiData(res.data.data);
        setIsLoading(false);
      } catch (e) {
        if (e?.response?.status === 404) {
          navigate("/failed", {
            state: {
              reason: "Session expired",
            },
          });
        } else {
          navigate("/failed", {
            state: {
              reason: null,
            },
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="card-container">
      <Card sx={cardStyle}>
        <div style={titleStyle}>
          Please enter your phone number and new 6 digit pin
        </div>
        <PhoneNumberField
          error={!phoneValidate(state.phoneNumber)}
          helperText={"Phone Number is invalid"}
          value={state.phoneNumber}
          onChange={onChangeHandler}
          name="phoneNumber"
          label="Phone number"
        />
        <PasswordField
          error={state.password.length !== 6 && state.password.length !== 0}
          helperText={"Password length must be 6"}
          value={state.password}
          onChange={onChangeHandler}
          name="password"
          label="Pin"
          style={textFieldStyle}
        />
        <PasswordField
          error={
            state.confirmPassword !== "" &&
            state.password !== state.confirmPassword
          }
          helperText={"Pins do not match"}
          value={state.confirmPassword}
          onChange={onChangeHandler}
          name="confirmPassword"
          style={textFieldStyle}
          label="Confirm Pin"
        />

        <Button
          disabled={
            
            state.phoneNumber.length === 0 ||
            state.password.length !== 6 ||
            state.password !== state.confirmPassword ||
            !phoneValidate(state.phoneNumber)
          }
          sx={buttonStyle}
          variant="contained"
          fullWidth
          onClick={handleOpenModal}
        >
          Submit
        </Button>
      </Card>

      <CaptchaModal
        handleCloseModal={handleCloseModal}
        onSubmit={onSubmit}
        openModal={openModal}
      />
    </div>
  );
}
