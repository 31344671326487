export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};
export const buttonStyle = {
  fontSize: "1rem",
  color: "white",
  padding: "10px 0px",
  marginTop: "5px",
  background:'linear-gradient(90deg, #c6952c 35%, #e9be5a 100%)'
};

export const textFieldStyle = { marginTop: "5px" };

export const cardStyle = {
  maxWidth: 500,
  width: "80%",
  padding: "30px 20px",
};

export const titleStyle = {
  fontSize: "1.05rem",
  marginBottom: "25px",
  fontWeight: 600,
};
