import React from "react";

export default function Success() {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
    textAlign: "center",
  };
  const primaryTextStyle = {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#081939",
    marginBottom: "0.3rem",
  };
  const subTextStyle = {
    fontSize: "1.1rem",
    fontWeight: 500,
    color: "#CEC382",
  };
  return (
    <div style={containerStyle}>
      <div style={primaryTextStyle}>
        Your pin has been successfully reset
      </div>
      <div style={subTextStyle}>Now try log in to your account from app</div>
    </div>
  );
}
