import { AppBar, Toolbar } from "@mui/material";
import React from "react";
import logo from "../assets/images/gklogo.png";
export default function Layout({ children }) {
  return (
    <div>
      <AppBar position="static" color="secondary" enableColorOnDark>
        <Toolbar>
          <div style={
            {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }
          }>
          <img src={logo} alt="logo" />
          </div>
          
        </Toolbar>
      </AppBar>
      <main>{children}</main>
    </div>
  );
}
