import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import BottomSpacer from "./BottomSpacer";

export default function PhoneNumberField({
  error,
  helperText,
  value,
  onChange,
  name,
  label,
  style,
}) {
  return (
    <>
      <TextField
        error={error}
        helperText={error && helperText ? helperText : ""}
        fullWidth
        label={label}
        type="tel"
        value={value}
        name={name}
        onChange={(e) => onChange(e)}
        sx={style ? style : {}}
        required
        InputProps={{
          startAdornment: <InputAdornment position="start">+88</InputAdornment>,
        }}
      />
      <BottomSpacer isVisible={!error} />
    </>
  );
}
