export const phoneValidate = (phoneNumber) => {
    if (phoneNumber.length === 0) return true;
    if (
      phoneNumber.length !== 11 ||
      !(
        phoneNumber.startsWith("013") ||
        phoneNumber.startsWith("014") ||
        phoneNumber.startsWith("015") ||
        phoneNumber.startsWith("016") ||
        phoneNumber.startsWith("017") ||
        phoneNumber.startsWith("018") ||
        phoneNumber.startsWith("019")
      )
    )
      return false;
    return true;
  };
export const validateInputs = (state) => {
    if (phoneValidate(!state.phoneNumber)) {
      // alert("Phone number is not valid");
      return false;
    }
    if (state.password.length !== 6) {
      // alert("Password length must be 6 ");
      return false;
    } else if (state.password !== state.confirmPassword) {
      // alert("Password and Confirm Password must be same");
      return false;
    }
    return true;
  };