import React from "react";
import { useLocation } from "react-router-dom";

export default function Failed() {
  const location = useLocation();
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
    textAlign: "center",
  };
  const primaryTextStyle = {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#FA113D",
    marginBottom: "0.3rem",
  };
  const subTextStyle = {
    fontSize: "1.1rem",
    fontWeight: 500,
    color: "#CEC382",
  };
  return (
    <div style={containerStyle}>
      <div style={primaryTextStyle}>Your pin change request failed</div>
      <div style={subTextStyle}>
        {location.state?.reason
          ? location.state.reason + ", please contact with customer care"
          : "Please contact with customer care"}
      </div>
    </div>
  );
}
