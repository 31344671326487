import React from "react";
import logo from "../assets/images/gklogo.png";

export default function Loader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <img src={logo} alt="loading" />
    </div>
  );
}
