import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import Theme from "./themes/Theme";
import './App.css';

import * as AppRoutes from "./constants/Routes";

import Layout from "./layouts/PrimaryLayout";

import Home from "./pages/Home/Home";
import Success from "./pages/Success";
import Failed from "./pages/Failed";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <Router>
        <Routes>
          <Route
            path={AppRoutes.ROOT}
            element={
              <Layout>
                <Home />
              </Layout>
            }
          ></Route>
          <Route
            path={AppRoutes.SUCCESS}
            element={
              <Layout>
                <Success />
              </Layout>
            }
          ></Route>
          <Route
            path={AppRoutes.FAILED}
            element={
              <Layout>
                <Failed />
              </Layout>
            }
          ></Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
