import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// colors
const primary = "#CEC382";
const fontLightGrey = "#4d6575";
const navigationColor = "#48484a";
const iconColor = "#757575";
const secondary = "#081939";
const LightBlue = "#DAECFF";
const grey = "#F8F8F8";
const darkerGrey = "#808080";
const softGrey = "rgba(72, 72, 74, 0.8)";
const greyBlack = "#343a40";
const black = "#000";
const white = "#FFF";
const error = "#f44336";
const darkBlack = "rgb(36, 40, 44)";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "#DB4A39";
const warningDark = "rgba(253, 200, 69, .7)";
const printButton = "#FFAF38";
const darkGrey = "#ddd";
const lightBlack = "#464444";
const successDark = "#388e3c";
const successLight = "#81c784";
const successMain = "#1faa00";

// icons color
const googlePlus = "#DB4A39";
const twitterColor = "#00ACEE";
const fbColor = "#4267B2";
const starRating = "#FFE622";
const whishlistIconColor = "#D90000";

// Backgound
// const background = '#f5f5f5';
const background = "#F8F8F8";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
    background: {
      default: background,
      footer: primary,
      secondary: primary,
    },
    common: {
      greyBlack,
      white,
      black,
      darkBlack,
      error,
      grey,
      LightBlue,
      darkGrey,
      darkerGrey,
      softGrey,
      lightBlack,
      fontLightGrey,
    },
    navigation: {
      navigationColor,
    },
    success: {
      light: successLight,
      main: successMain,
      dark: successDark,
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
    },
    button: {
      printButton,
    },
    icon: {
      iconColor,
      googlePlus,
      fbColor,
      twitterColor,
      starRating,
      whishlistIconColor,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    spacing,
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      xs,
    },
  },

  fonts: {
    paragaphFontfooter: "9px",
    font11: "11px",
    font13: "13px",
    font14: "14px",
    font15: "15px",
    font16: "16px",
    font17: "17px",
    font19: "19px",
    font21: "21px",
    font24: "24px",
  },

  typography: {
    fontFamily: "'Montserrat'",
    h1: {
      display: "inline-block",
      fontSize: "2em",
      marginTop: "0.67em",
      marginBottom: "0.67em",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.5em",
      marginTop: "0.67em",
      marginBottom: "0.67em",
      fontWeight: "bold",
    },
    h3: {
      color: black,
      fontSize: 16,
      fontWeight: 500,
      paddingBottom: "0.5rem",
    },
    subtitle1: {
      color: darkerGrey,
      fontSize: 18,
    },
    subtitle2: {
      color: darkerGrey,
      fontSize: 13,
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
