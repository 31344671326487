import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";

export default function CaptchaModal({
  openModal,
  handleCloseModal,
  onSubmit,
}) {
  const navigate = useNavigate();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <ReCAPTCHA
          size="normal"
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={() => {
            handleCloseModal();
            onSubmit();
          }}
          onExpired={() => navigate("/failed")}
        />
      </Box>
    </Modal>
  );
}
